import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { reject } from 'bluebird';

@Injectable()
export class ApiService {

	url: String;
	token: String;

	constructor(private http: Http) {
		this.url = 'https://formulaire.beautybusiness.fr/api';
		// this.url = 'http://localhost:4004/api';
	}

	formJoin(body: any): Promise<Object> {
		return new Promise((resolve, reject) => {
			this.http.post(this.url + '/form-join', body).subscribe(data => {
				if (data.json()) {
					resolve(data.json());
				} else {
					resolve({});
				}
			}, error => {
				reject({ message: error._body });
			});
		});
	}

	upload(photo: File): Promise<Object> {
		return new Promise((resolve, reject) => {
			const formData = new FormData();
			formData.append('avatar', photo, photo.name);
			this.http.post(this.url + '/upload', formData).subscribe(data => {
				if (data.json()) {
					resolve(data.json());
				} else {
					resolve({});
				}
			}, error => {
				reject({ message: error._body });
			});
		});
	}

	existOnPrestashop(email: string): Promise<Object> {
		return new Promise((resolve, reject) => {
			this.http.get(this.url + '/exist-on-prestashop/' + email).subscribe(data => {
				if (data.json()) {
					resolve(data.json());
				} else {
					resolve({});
				}
			}, error => {
				reject({ message: error._body });
			});
		});
	}

}
