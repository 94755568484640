import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../../src/app/services/api.service';

declare var Stripe;
const stripe = Stripe('pk_live_51IGVDgA5dDZodhUVOvWygpOTcNMgWovUIjd5yv9dww9RPWfr3Ojp8ne6Gtou7uzQG15m8SjhevAohehatCQfLc2O00U0YQ2TxM');
// const stripe = Stripe('pk_test_51IGVDgA5dDZodhUVp25lDz5qSP0IbpkUamCQcMBxHY8GRGmWaGOG5ALSATXSftNO4EALgOz0osTATVaNuPY5HGup00caGClAl3');

@Component({
	selector: 'app-form-join',
	templateUrl: './form-join.component.html',
	styleUrls: ['./form-join.component.css']
})
export class FormJoinComponent implements OnInit {

	form: any = {
		lastname: null,
		firstname: null,
		email: null,
		mobile: null,
		address: null,
		zipcode: null,
		city: null,
		birthday: null,
		activityDomain: null,
		xp: null,
		yearsXp: null,
		professionalProject: null,
		currentSituation: null,
		position_short: null,
		position: null,
		avatar: null,
		acceptConditions: false,
		stripeSessionId: null,
		source: 'website',
		nameFirstContact: null,
		emailEcommerce: null,
		whyThisRecruitmentSession: null
	};

	url = 'https://formulaire.beautybusiness.fr';
	// url = 'http://localhost:4004';

	stripePriceId = 'price_1KmTe4A5dDZodhUVITyg3H7D'; // prod
	// stripePriceId = 'price_1KmTgIA5dDZodhUVJSNHabuM'; // test

	buttonText: String = 'Adhérer et payer';
	buttonDisabled: boolean = false;
	errorMessage: String;
	successMessage: String;

	uploadForm: FormGroup;

	positionShortChoices: Array<String> = [
		`en PREPA, je découvre le métier ou je valide mes compétences`,
		`en FORMATION, j'acquière et je développe des compétences`,
		`ÉQUIPIER, j'intègre un salon en bénéficiant de l'expérience d'un expert pour grandir`,
		`EXPERT - 1, j'accompagne un équipier et me familiarise avec le management`,
		`EXPERT - 2, je pilote 2 équipiers`,
		`TRAINER, FORMATEUR, je manage ou entraîne plusieurs équipes`,
		`ENTREPRENDRE, je souhaite évoluer vers l'entrepreneuriat`,
		`Autre`,
		`Étudiant`,
		`Salarié`,
		`Recherche d'emploi`,
		`Entrepreneur`
	];
	positionChoices: Array<String> = [
		`en PREPA, je découvre le métier ou je valide mes compétences`,
		`en FORMATION, j'acquière et je développe des compétences`,
		`ÉQUIPIER, j'intègre un salon en bénéficiant de l'expérience d'un expert pour grandir`,
		`EXPERT - 1, j'accompagne un équipier et me familiarise avec le management`,
		`Manager un salon de coiffure/esthétique`,
		`Être formateur`,
		`Entreprendre, je souhaite évoluer vers l'entreprenariat`,
		`Autre`,
		`Travailler de manière indépendante `,
		`Être coiffeur salarié`
	];
	whyThisRecruitmentSessionChoices: Array<string> = [
		`Comptable`,
		`Cap coiffure barbier`,
		`Coiffeuse `,
		`Formatrice CAP esthétique `,
		`Coiffure `,
		`Comptable `,
		`Mission locale `,
		`Coiffeur homme barbier `,
		`Coiffeur Barbier`,
		`Apprentie coiffeuse `,
		`Alternance coiffure `,
		`Coiffeuse`,
		`Poste de coiffeuse (salariée)`,
		`Manager puis employeur`,
		`Alternance en Mention Complémentaire `,
		`Cap`,
		`Apprenti BP Coiffure première année `,
		`Jean Claude Aubry basic`,
		`Formateur en Esthétique`,
		`Cap coiffure en alternance `,
		`Formation `,
		`Coiffeuse - esthéticienne`,
		`CAP Coiffure`,
		`Barbier`,
		`Esthéticienne Indépendante`,
		`Apprenti Coiffure`,
		`Coiffure Homme`,
		`Cap coiffure `,
		`Coiffeur Barbeur `,
		`Apprentie`,
		`barbier couffeur homme`,
		`Esthéticienne `,
		`Coiffeur barbier`,
		`Alternance en coiffure cap 2ans`,
		`Formateur`,
		`Coiffeuse en apprentissage de bp`,
		`Formatrice`,
		`Apprentissage `,
		`Formateur Esthétique cosmétique parfumerie`,
		`Coiffeuse à domicile `,
		`Équipier`,
		`Un poste en esthétique`,
		`Coiffeuse professionnelle `,
		`Coiffure`,
		`Coloriste`,
		`CAP coiffure`,
		`Community manager `,
		`Formatrice `,
		`Bp contrat pro`,
		` cap coiffure `,
		`Professeur d’esthétique `,
		`coiffeuse`,
		`Assistante coiffeuse `,
		`Bp coiffure `,
		`STAGIAIRE `,
		`Coiffeur/Barbier `,
		`Un poste dans les fonctions support`,
		`Cap alternance coiffure `,
		`Découverte `,
		`Stage `,
		`Barber`,
		`Je souhaite être une élève du groupe Jean Claude Aubry Community `,
		`Bp Coiffure`,
		`Équipier , travailler en salon `,
		`Coiffeur`,
		`Apprenti `,
		`Cap esthétique `,
		`Barbier `,
		`Coiffure et vente de produits cosmétiques qui correspondrait au client.`,
		`Coiffeuse esthéticienne `,
		`coifure barber`,
		`Apprentissage alternance `,
		`coiffeuse Hommes et Femmes`,
		`Coiffeur homme`,
		`Coiffeur trainer`,
		`Coiffeur mixte`,
		`Coiffeur barbier `,
		`Assistant coiffeur`,
		`Formation savoir Fhair `,
		`Trainer`,
		`Barbier coiffeur homme`,
		`Un poste en coiffure `,
		`Formation coiffure `,
		`Comptable générale `,
		`CAP coiffure contrat d'apprentissage`,
		`Coiffure visagiste`,
		`Cap coiffure`,
		`Une formation en coiffure`,
		`Une formation en esthétique`,
		`Autre`
	];

	constructor(private apiService: ApiService, private route: ActivatedRoute) { }

	ngOnInit() {
		this.route.params.subscribe(params => {
			if (params['session_id']) {
				this.form = JSON.parse(sessionStorage.getItem('form-join'));
				this.form.stripeSessionId = params['session_id'];
				this.successMessage = 'Il ne vous reste plus qu\'à vérifier vos informations, ajouter une photo de profil et valider votre inscription. Votre paiement a bien été validé !';
				this.buttonText = 'Confirmer l\'adhésion';
			}
		});

		this.route.queryParams.subscribe(params => {
			if (params['source']) {
				switch (params['source']) {
					case 'artgestion-NeNxJrY6':
						this.form.source = 'artgestion';
						this.buttonText = 'Adhérer gratuitement';
						break;
					case 'franchises-ixnzHe34s6':
						this.form.source = 'franchises';
						break;
					case 'collaborateurs-NeNxJrY6':
						this.form.source = 'collaborateurs';
						break;
					default:
						break;
				}
			}
		});
	}

	async send() {
		this.buttonDisabled = true;
		this.successMessage = null;
		this.errorMessage = null;
		const parent = this;

		if (parent.form.acceptConditions === true) {
			if (parent.form.emailEcommerce) {
				if (parent.form.source === 'artgestion') {
					// If source is "Art & Gestion", the inscription is free !
					// PS: The vérification is not secure by choice. Verification will be done manually on Airtable.
					sendForm();
				} else {
					if (parent.form.stripeSessionId === null) {
						const promise: Promise<Object> = parent.apiService.existOnPrestashop(parent.form.emailEcommerce);
						promise.then(
							async (data:any) => {
								if (data.exist) {
									// All done
									// Pay request
									sessionStorage.setItem('form-join', JSON.stringify(parent.form));

									const { error } = await stripe.redirectToCheckout({
										mode: 'subscription',
										lineItems: [{ price: parent.stripePriceId, quantity: 1 }],
										successUrl: `${this.url}/rejoindre/{CHECKOUT_SESSION_ID}`,
										cancelUrl: `${this.url}/rejoindre`
									});
									parent.buttonDisabled = false;
									if (error) {
										parent.errorMessage = error.message;
									}
								} else {
									parent.buttonDisabled = false;
									parent.errorMessage = 'Aucun compte Maison Aubry Boutique n\'a été trouvé avec ce mail. Impossible de continuer';
								}
							}
						).catch(error => {
							parent.buttonDisabled = false;
							parent.errorMessage = error.message;
						});
					} else {
						// Pay success
						sendForm();
					}
				}
			} else {
				parent.buttonDisabled = false;
				parent.errorMessage = 'Veuillez saisir l\'adresse email de votre compte Maison Aubry Boutique';
			}
		} else {
			parent.buttonDisabled = false;
			parent.errorMessage = 'Veuillez accepter nos conditions d\'adhésion';
		}

		function sendForm() {
			const promise: Promise<Object> = parent.apiService.formJoin(parent.form);
			promise.then(
				data => {
					parent.successMessage = 'Votre adhésion est confirmée, vous allez bientôt être contacté. Merci !';
				}
			).catch(error => {
				parent.buttonDisabled = false;
				parent.errorMessage = error.message;
			});
		}
	}

	onFileSelect(event) {
		this.successMessage = null;
		this.errorMessage = null;
		this.buttonDisabled = true;

		if (event.target.files.length > 0) {
			const file = event.target.files[0];

			const promise: Promise<Object> = this.apiService.upload(file);
				promise.then(
					(data: any) => {
						this.form.avatar = data.url;
						this.buttonDisabled = false;
					}
				).catch(error => {
					this.errorMessage = error.message;
					this.buttonDisabled = false;
				});
		} else {
			this.buttonDisabled = false;
		}
	}

	onSubmit() {
		const formData = new FormData();
		formData.append('file', this.uploadForm.get('profile').value);
	}

}
