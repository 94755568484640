import { Component } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { GlobalForComponents } from './components/global-for-components';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent {

	year: Number;

	constructor(private router: Router, public g: GlobalForComponents) {
		const date = new Date();
		this.year = date.getFullYear();

		this.router.events.subscribe((event) => {
			if (typeof (window) !== 'undefined') {
				// console.log(event);
				window.scrollTo(0, 0);
			}
		});

	}
}
