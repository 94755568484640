import { Injectable } from '@angular/core';

@Injectable()
export class GlobalForComponents {

	errorMsg: String;
	successMsg: String;
	infoMsg: String;
	showErrorMsg: Boolean = false;
	showSuccessMsg: Boolean = false;
	showInfoMsg: Boolean = false;

	setMessage(success: Boolean, msg: String) {
		if (!success) {
			this.errorMsg = msg;
			this.showSuccessMsg = false;
			this.showErrorMsg = true;
			this.showInfoMsg = false;
		} else {
			this.successMsg = msg;
			this.showSuccessMsg = true;
			this.showErrorMsg = false;
			this.showInfoMsg = false;
		}
	}

	hideMesssage(hide: Boolean = true) {
		if (hide) {
			this.errorMsg = '';
			this.successMsg = '';
			this.infoMsg = '';

			this.showErrorMsg = false;
			this.showSuccessMsg = false;
			this.showInfoMsg = false;
		}
	}

	setInformation(msg: String) {
		this.errorMsg = '';
		this.successMsg = '';
		this.infoMsg = msg;

		this.showErrorMsg = false;
		this.showSuccessMsg = false;
		this.showInfoMsg = true;
	}

	setSnackbar(message: string, timeout: number = 3) {
		const snackbar = document.getElementById('snackbar');
		snackbar.innerText = message;
		snackbar.className = 'show';
		setTimeout(() => {
			snackbar.className = snackbar.className.replace('show', '');
		}, timeout * 1000);
	}

	async asyncForEach(array, callback) {
		for (let index = 0; index < array.length; index++) {
			await callback(array[index], index, array);
		}
	}
}
