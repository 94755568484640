import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';

import { ApiService } from './services/api.service';

import { GlobalForComponents } from './components/global-for-components';

import { AppComponent } from './app.component';
import { FormJoinComponent } from './components/form-join/form-join.component';

const appRoutes: Routes = [
	{
		path: '',
		component: FormJoinComponent
	},
	{
		path: 'rejoindre',
		component: FormJoinComponent
	},
	{
		path: 'rejoindre/:session_id',
		component: FormJoinComponent
	}
];

@NgModule({
	declarations: [
		AppComponent,
		FormJoinComponent
	],
	imports: [
		BrowserModule.withServerTransition({ appId: 'formulaire-cartecoiffure' }),
		RouterModule.forRoot(
			appRoutes,
			{ enableTracing: false } // <-- debugging purposes only
		),
		FormsModule,
		HttpModule
	],
	providers: [RouterModule, GlobalForComponents, ApiService],
	bootstrap: [AppComponent]
})
export class AppModule { }
